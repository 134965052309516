import { ApxSolsticeWebClientService } from '@apx-ui/apx-web-api-v1';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UINotificationStateService } from '@apx-ui/apx-shared-ui';

@Component({
  selector: 'apx-ui-shared-pdf-icon-cell',
  templateUrl: './pdf-icon-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfIconCellComponent {

  params: any;

  constructor(
      private readonly client: ApxSolsticeWebClientService,
      private readonly notificationService: UINotificationStateService,
    ) {}

  agInit(params: any,
  ): void {
    this.params = params;
  }

  openPdf(pdfUrl: string): void {
    this.client.getCOAPdfReport({
      fileName: pdfUrl,
    })
      .subscribe({
        next: response => {
          const file = new Blob([response.body], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        },
        error: () => {
          this.notificationService.error('Error occurred while downloading COA Pdf file');
        },
      });
  }

}
